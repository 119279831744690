import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import './not-found.scss'
function NotFound() {
  return (
    <div className="container not-found">
      404
    </div>
  );
}

export default NotFound;
